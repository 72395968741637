@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 255, 255, 255;
  --background-start-rgb: 0, 0, 0;
  --background-end-rgb: 0, 0, 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: sans-serif;
  color: rgb(var(--foreground-rgb));
  background: black
}

.grid-background {
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
  padding: 20px; /* adjust padding as needed */
  border-radius: 10px; /* rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
}


@layer utilities {
  .bg-custom-gradient {
    background: linear-gradient(to right, #0a0a0a, #1e3a8a, #1e40af, #1e3a8a, #0a0a0a);
  }
}
.text-highlight {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
  background-blend-mode: overlay;
  padding: 0.5em;
  border-radius: 0.5em;
  display: inline-block;
}


.gradient-text {
  background: linear-gradient(to right, #ff66ff, #a96cff, #2c41ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-splash {
  background: linear-gradient(to right, #ffffff, #707070);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}


.hover-effect:hover {
  color: purple;
}

nav a {
  color: white;
  text-decoration: none;
  margin-right: 1rem;
}

.login-button {
  flex-shrink: 1;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.premium-button {
  flex-shrink: 1;
  padding: 10px 20px;
  background-color: rgba(252, 196, 73, 0.5);
  color: #ff9d1c;
  border-radius: 5px;
}

.premium-button:hover {
  opacity: 0.5;
}


.login-button:hover {
  opacity: 0.5;
}

.profile-picture {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}


@media (max-width: 768px) {

  /* Adjust breakpoint as needed */
  .content-container {
    flex-direction: column;
    /* Stack items vertically on smaller screens */
  }
}


.hover-effect:hover {
  cursor: pointer;
  /* Add other hover styles if needed */
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.error-slide-in {
  animation: slideIn 0.5s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-10%);
    opacity: 0;
  }
}

.animated-slide-up {
  animation: slideUp 0.25s ease-out forwards;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #475569;
  color: white;
}

.animated-dropdown {
  animation: slideDown 0.25s ease-out forwards;
  border-radius: 10px;
}

.absolute {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.divider {
  height: 1px;
  background-color: #475569;
  /* Adjust the color to match the design */
  width: 80%;
  /* Set the width of the divider to 80% */
  margin: 8px auto;
  /* Top and bottom margin with auto horizontal margins */
}


.slide-in {
  transform: translateX(0);
  /* Sidebar in its normal position */
  transition: transform 0.3s ease-in-out;
}

.slide-out {
  transform: translateX(-5%);
  /* Sidebar fully translated to the left */
  transition: transform 0.3s ease-in-out;
}

.error-slide-in {
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
}

.error-slide-out {
  transition: transform 0.5s ease-in-out;
  transform: translateX(100%);
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: white;
  }
}

.typing-text {
  overflow: hidden;
  white-space: nowrap;
  border-right: .15em solid white;
  animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
}

@keyframes ladder {
  0% {
      transform: translateY(-10px);
      opacity: 0;
  }
  100% {
      transform: translateY(0);
      opacity: 1;
  }
}
@keyframes slideDown {
  0% {
      max-height: 0;
      opacity: 0;
      transform: translateY(-20px);
  }
  100% {
      max-height: 10rem; /* Adjust based on your dropdown content */
      opacity: 1;
      transform: translateY(0);
  }
}
/* Custom legend styles */
#chart-legend {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  font-family: Arial, sans-serif;
}

#chart-legend div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

#chart-legend img {
  margin-right: 8px;
  border-radius: 4px;
}

#chart-legend span {
  font-size: 14px;
}
